// analytics.service.ts


// Google Analytics identifier. Production and staging have different values
const googleAnalytics = 'G-1CW3T8L4VS'; // Staging

//const googleAnalytics = 'G-8RL8WMQ3M1'; // Production




export default class AnalyticsService
{
  public getGoogleId() : string {
    return googleAnalytics;
  }

  /**
   * Generate a page_view event
   * @param pageTitle 
   * @param pageLocation 
   */
  public pageView(pageTitle: string, pageLocation: string)
  {
    (window as any).gtag('event', 'page_view', {
      page_title: pageTitle,
      page_location: pageLocation
    });
  }


  /**
   * Write an event
   * @param path {string} Path to write to Analytics (ie. location.pathname + location.search + location.hash)
   * @param search {string} Search component (ie. query parameters to show). Default = ''
   * @param hash {string} Any hash information. Default = ''
   */
  public pageTracking(eventName: string, path: string, parameters: any = '', hash: string = '')
  {
    (window as any).gtag("event", eventName, parameters);
/*
    {
      path: path,     // location.pathname + location.search + location.hash,
      page_search: search, // location.search,
      // page_hash: hash,     // location.hash,
    });
*/
  }

}
